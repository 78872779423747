<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-muted justify-content-end small">
        <li class="breadcrumb-item">
          {{ $t("id") }}:
          <span class="badge bg-secondary">{{ formEdit.id }}</span>
        </li>
        <li class="breadcrumb-item">
          {{ $t("dateInsert") }}:
          <span class="badge bg-secondary" v-if="moment">
            {{ moment(formEdit.created_at).format("DD/MM/YYYY") }}
          </span>
        </li>
      </ol>
    </nav>
    <table class="table table-sm table-striped table-hover">
      <thead>
        <tr class="bg-ideias">
          <th colspan="3">
            <h5>{{ formEdit.pergunta }}</h5>
          </th>
        </tr>
        <tr class="bg-greey">
          <th>Letra</th>
          <th>Respostas</th>
          <th>Votos</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="formEdit.opcaoa">
          <td>A)</td>
          <td>{{ formEdit.opcaoa }}</td>
          <td>
            <span class="badge bg-ideias text-blue">{{respostaList.a}}</span>
          </td>
        </tr>
        <tr v-if="formEdit.opcaob">
          <td>B)</td>
          <td>{{ formEdit.opcaob }}</td>
          <td>
            <span class="badge bg-ideias text-blue">{{respostaList.b}}</span>
          </td>
        </tr>
        <tr v-if="formEdit.opcaoc">
          <td>C)</td>
          <td>{{ formEdit.opcaoc }}</td>
          <td>
            <span class="badge bg-ideias text-blue">{{respostaList.c}}</span>
          </td>
        </tr>
        <tr v-if="formEdit.opcaod">
          <td>D)</td>
          <td>{{ formEdit.opcaod }}</td>
          <td>
            <span class="badge bg-ideias text-blue">{{respostaList.d}}</span>
          </td>
        </tr>
        <tr v-if="formEdit.opcaoe">
          <td>E)</td>
          <td>{{ formEdit.opcaoe }}</td>
          <td>
            <span class="badge bg-ideias text-blue">{{respostaList.e}}</span>
          </td>
        </tr>
        <tr v-if="formEdit.opcaof">
          <td>F)</td>
          <td>{{ formEdit.opcaof }}</td>
          <td>
            <span class="badge bg-ideias text-blue">{{respostaList.f}}</span>
          </td>
        </tr>
        <tr v-if="formEdit.opcaog">
          <td>G)</td>
          <td>{{ formEdit.opcaog }}</td>
          <td>
            <span class="badge bg-ideias text-blue">{{respostaList.g}}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div>
      <h4>
        Total de Votos Computados: <span class="badge bg-secondary">{{respostaTotal}}</span>
      </h4>
    </div>
    <hr />
    <table class="table table-sm table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center">ID</th>
          <th>Resposta</th>
          <th>Letra</th>
          <th>Data Cadastro</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in list" :key="item.id">
          <td class="text-center">{{ item.id }}</td>
          <td>{{ item.resposta }}</td>
          <td>{{ item.resposta_letra }}</td>
          <td>{{ moment(item.created_at).format("DD/MM/YYYY") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RespostaService from "../../../../../services/resposta.service";
import moment from "moment";
export default {
  props: ["dataItem", "errorServer"],
  data: () => ({
    formEdit: {},
    urlImg: "",
    urlPdf: "",
    loading: false,
    list: [],
    moment: null,
    respostaList: { a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0 },
    respostaTotal: 0,
  }),
  mounted() {
    this.moment = moment;
    this.moment.locale("pt-br");
    this.formEdit = this.dataItem;
    this.reloadData();
  },
  methods: {
    reloadData: function () {
      RespostaService.getAll(this.formEdit.id).then((result) => {
        this.list = result.data;
        this.list.forEach(element => {
          if(element.resposta_letra == "a"){
            this.respostaList.a += 1;
            this.respostaTotal += 1;
          }
          if(element.resposta_letra == "b"){
            this.respostaList.b += 1;
            this.respostaTotal += 1;
          }
          if(element.resposta_letra == "c"){
            this.respostaList.c += 1;
            this.respostaTotal += 1;
          }
          if(element.resposta_letra == "d"){
            this.respostaList.d += 1;
            this.respostaTotal += 1;
          }
          if(element.resposta_letra == "e"){
            this.respostaList.e += 1;
            this.respostaTotal += 1;
          }
          if(element.resposta_letra == "f"){
            this.respostaList.f += 1;
            this.respostaTotal += 1;
          }
          if(element.resposta_letra == "g"){
            this.respostaList.g += 1;
            this.respostaTotal += 1;
          }
        });
      });
    },
  },
};
</script>

<style>
</style>