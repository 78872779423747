import axios from 'axios';
import authHeader from './auth-header';

import { URL_API } from './Commons';

class EnqueteService {

  async getAll() {
    return await axios.get(URL_API + 'enquetes', { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(URL_API + 'enquete/' + id, { headers: authHeader() });
  }

  insert(enquete) {
    return axios.post(URL_API + 'enquete', enquete, { headers: authHeader() });
  }

  update(id, enquete) {
    return axios.put(URL_API + 'enquete/' + id, enquete, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(URL_API + 'enquete/' + id, { headers: authHeader() });
  }

}

export default new EnqueteService();