<template>
  <div>
    <form @submit="sendNew">
      <div class="col-12 mb-3">
        <label for="pergunta" class="form-label">Pergunta</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.pergunta"
          aria-describedby="name_help"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoa" class="form-label">Opção A</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaoa"
          aria-describedby="opcaoa"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaob" class="form-label">Opção B</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaob"
          aria-describedby="opcaob"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoc" class="form-label">Opção C</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaoc"
          aria-describedby="opcaoc"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaod" class="form-label">Opção D</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaod"
          aria-describedby="opcaod"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoe" class="form-label">Opção E</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaoe"
          aria-describedby="opcaoe"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaof" class="form-label">Opção F</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaof"
          aria-describedby="opcaof"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaog" class="form-label">Opção G</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaog"
          aria-describedby="opcaog"
        />
      </div>
      <div class="col-12 mb-3"  v-if="!deleteMode">
        <label for="lang_input" class="form-label">{{
          $t("status")
        }}</label>
        <select
          class="form-select"
          @change="formInclude.status = $event.target.value"
          v-model="formInclude.status"
        >
          <option value="1">Ativo</option>
          <option value="0">Inativo</option>
        </select>
      </div>
      <div class="d-grid gap-2">
        <button
          class="btn btn-success bg-success bg-gradient col-6 mx-auto"
          type="submit"
        >
          {{ $t("enviar") }} {{ $t("newone") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ["actionSend"],
  data: () => ({
    formInclude: {},
  }),
  mounted() {},
  methods: {
    sendNew: function (event) {
      event.preventDefault();
      this.actionSend(this.formInclude);
    },
  },
};
</script>

<style>
</style>