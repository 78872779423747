import axios from 'axios';
import authHeader from './auth-header';

import { URL_API } from './Commons';

class RespostaService {

  async getAll(idEnquete) {
    return await axios.get(URL_API + 'enqresps/'+idEnquete, { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(URL_API + 'enqresp/' + id, { headers: authHeader() });
  }

  insert(resposta) {
    return axios.post(URL_API + 'enqresp', resposta, { headers: authHeader() });
  }

  update(id, resposta) {
    return axios.put(URL_API + 'enqresp/' + id, resposta, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(URL_API + 'enqresp/' + id, { headers: authHeader() });
  }

}

export default new RespostaService();