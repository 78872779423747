class EnqueteHandle {

    formToJso(formData) {
        return {
            id: formData.id,
            pergunta: formData.pergunta,
            opcaoa: formData.opcaoa,
            opcaob: formData.opcaob,
            opcaoc: formData.opcaoc,
            opcaod: formData.opcaod,
            opcaoe: formData.opcaoe,
            opcaof: formData.opcaof,
            opcaog: formData.opcaog,
            status: formData.status,
        };
    }

}

export default new EnqueteHandle();